import React from 'react';
import { Field } from 'formik';
import Select from "react-select";
import classnames from "classnames";
import "../forms.scss";

const BirthDate = (props) => {
    const { label, name, required, ...rest } = props;

    const ifRequired = () => {
        if (required == "true") {
            return <span className="required">*</span>
        }
    }

    return (
        <div>
            <Field name={name} {...rest}>
                {({ field, meta }) => (
                    <div className={classnames("partie-form__field-container", {
                        'partie-form__field-container--error': meta.error && meta.touched
                    })}>
                        <label className="partie-form__label" htmlFor={name}>
                            {label} {ifRequired()}
                        </label>
                        <input
                            className="partie-form__field"
                            id={name}
                            type="date"
                            onChange={field.onChange}
                        />
                        {(meta.error && meta.touched) && (
                            <span className="partie-form__field-caption">{meta.error}</span>
                        )}
                    </div>
                )}
            </Field>
        </div>
    );
};

export default BirthDate;